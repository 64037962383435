import Vue from 'vue'
import VueRouter from 'vue-router'

import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'


Vue.use(VueRouter)

  const routes = [    
    {
      path: '/', 
      name: 'Home',
      components: {
        default: () => import('@/views/Home.vue'),
        header: Header,
        footer: Footer    
      },     
    },
    {
      path: '/cyberbullying-kutatas', 
      name: 'Cikk 1',
      components: {
        default: () => import('@/views/Article1.vue'),
        header: Header,
        footer: Footer    
      },     
    },
    {
      path: '/cyberbullying', 
      name: 'Cikk 2',
      components: {
        default: () => import('@/views/Article2.vue'),
        header: Header,
        footer: Footer    
      },     
    },
    {
      path: '/a-bantalmazo-is-aldozat', 
      name: 'Cikk 3',
      components: {
        default: () => import('@/views/Article3.vue'),
        header: Header,
        footer: Footer    
      },     
    },
    {
      path: '/anonimitas', 
      name: 'Cikk 4',
      components: {
        default: () => import('@/views/Article4.vue'),
        header: Header,
        footer: Footer    
      },     
    },
    {
      path: '/igazi-e-a-digitalis-fajdalom', 
      name: 'Cikk 5',
      components: {
        default: () => import('@/views/Article5.vue'),
        header: Header,
        footer: Footer    
      },     
    },
    {
      path: '/okos-vasarlas-okos-szokasok', 
      name: 'Cikk 6',
      components: {
        default: () => import('@/views/Article6.vue'),
        header: Header,
        footer: Footer    
      },     
    },
    {
      path: '/masok-mercejevel-mered-magad', 
      name: 'Cikk 7',
      components: {
        default: () => import('@/views/Article7.vue'),
        header: Header,
        footer: Footer    
      },     
    },
    {
      path: '/mit-mutatsz-magadrol-a-kozossegi-mediaban', 
      name: 'Cikk 8',
      components: {
        default: () => import('@/views/Article8.vue'),
        header: Header,
        footer: Footer    
      },     
    },
    {
      path: '/online-kocsmai-verekedesek', 
      name: 'Cikk 9',
      components: {
        default: () => import('@/views/Article9.vue'),
        header: Header,
        footer: Footer    
      },     
    },
    {
      path: '/uvegcipo', 
      name: 'Cikk 10',
      components: {
        default: () => import('@/views/Article10.vue'),
        header: Header,
        footer: Footer    
      },   
    },
    {
        path: '/uj-szokasok', 
        name: 'Cikk 11',
        components: {
          default: () => import('@/views/Article11.vue'),
          header: Header,
          footer: Footer    
        },  
    },
    {
      path: '/kipukkasztando-buborekok', 
      name: 'Cikk 12',
      components: {
        default: () => import('@/views/Article12.vue'),
        header: Header,
        footer: Footer    
      },  
  },
  {
    path: '/nyaralas', 
    name: 'Cikk 13',
    components: {
      default: () => import('@/views/Article13.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/okos-tippek-fesztivalozashoz', 
    name: 'Cikk 14',
    components: {
      default: () => import('@/views/Article14.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/elmenyek-nyaralas-alatt', 
    name: 'Cikk 15',
    components: {
      default: () => import('@/views/Article15.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/hasznos-alkalmazasok', 
    name: 'Cikk 16',
    components: {
      default: () => import('@/views/Article16.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/deepfake', 
    name: 'Cikk 17',
    components: {
      default: () => import('@/views/Article17.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/online-szerelem', 
    name: 'Cikk 18',
    components: {
      default: () => import('@/views/Article18.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/a-nyelvtan-fontos', 
    name: 'Cikk 19',
    components: {
      default: () => import('@/views/Article19.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/cyberstalking', 
    name: 'Cikk 20',
    components: {
      default: () => import('@/views/Article20.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/fizetett-troll-hadsereg', 
    name: 'Cikk 21',
    components: {
      default: () => import('@/views/Article21.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/ha-hireket-olvasol', 
    name: 'Cikk 22',
    components: {
      default: () => import('@/views/Article22.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/tudom-hol-vagy', 
    name: 'Cikk 23',
    components: {
      default: () => import('@/views/Article23.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/verbalis-bantalmazas', 
    name: 'Cikk 24',
    components: {
      default: () => import('@/views/Article24.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/love-bombing', 
    name: 'Cikk 25',
    components: {
      default: () => import('@/views/Article25.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/erezd-magad-biztonsagban', 
    name: 'Cikk 26',
    components: {
      default: () => import('@/views/Article26.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/filterek', 
    name: 'Cikk 27',
    components: {
      default: () => import('@/views/Article27.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/bantalmazott-ferfiak', 
    name: 'Cikk 28',
    components: {
      default: () => import('@/views/Article28.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/testszegyenites', 
    name: 'Cikk 29',
    components: {
      default: () => import('@/views/Article29.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/overprotectiveness', 
    name: 'Cikk 30',
    components: {
      default: () => import('@/views/Article30.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/egyutterzes', 
    name: 'Cikk 31',
    components: {
      default: () => import('@/views/Article31.vue'),
      header: Header,
      footer: Footer    
    },  
  },
  {
    path: '/passziv-bantalmazas', 
    name: 'Cikk 32',
    components: {
      default: () => import('@/views/Article32.vue'),
      header: Header,
      footer: Footer    
    },  
  },
    {
      path: '*',
      redirect: '/'    
    }   
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  
})

export default router
