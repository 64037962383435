<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Önegyüttérzés
          </h2>
          <p class="hashText">– A bántalmazásból való kilépés</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" class="side-gap">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                A bántalmazásból való kilépés, a trauma feldolgozása és a gyógyulás folyamán egyaránt nagyon fontos, hogy képesek legyünk kedves, elfogadó módon, törődéssel viszonyulni önmagunkhoz. Az önegyüttérzés fogalma ezt a viszonyulást járja körül. Az elmélet megalkotója, Kristin Neff definíciója alapján az önegyüttérzés során a személy képes önmaga felé fordulni és ítélkezésmentesen, megértéssel és kedvességgel megfigyelni a saját érzéseit és gondolatait, ezzel elősegítve a biztonság érzését és a változtatásra való ösztönzést, valamint a változáshoz szükséges proaktív cselekvés végrehajtását.</p>

                <p class="text">
                Az önegyüttérzés nem csak egy viszonyulás, hanem cselekvés is! Egy tanulható, fejleszthető képesség, ami segít jobban megküzdeni a nehéz helyzetekkel, képes csökkenteni a trauma okozta stresszt és lehetőséget ad a saját magunkkal való pozitívabb kapcsolódásra, a szubjektív jóllét növelésére.</p>

                <p class="text">
                Az önegyüttérzés három fő komponensből áll: a kedvesség önmagunkkal, a közös emberi és a mindfulness, azaz tudatos jelenlét. </p>
                <p class="text">
                A magunk iránt tanúsított kedvességet olyan helyzetekben is fontos gyakorolnunk, amikor sikertelenek vagyunk valamiben, amikor érzelmileg nehéz helyzetben találjuk magunkat, vagy amikor nem úgy sikerülnek a dolgok, ahogy elterveztük. Ez a fajta kedvesség ellenszer lehet az önkritikával, a rágódással, a nehéz helyzetekből való meneküléssel szemben. A kedves, támogató odafordulás nemcsak növeli a biztonságérzetünket, de csökkentve a stresszszintet, segít hatékonyabban megküzdeni a nehézséggel.</p>
                <p class="text">
                A közös emberi gondolata senkinek sem új, mégis nagyon felszabadító tud lenni annak a megélése, hogy mindannyian küzdünk nehézségekkel, senki élete sem tökéletes és mindenki hibázhat. Azaz, hogy nem vagyunk egyedül, mások is megélhetnek a miénkhez hasonló nehézségeket. </p>
                <p class="text">
                A mindfulness a jelen pillanat átélését, bizonyos távolságból való szemlélését jelenti, elfogadó attitűddel. Annak a megfigyelését, hogy mit érzek, mit gondolok épp, mi vesz engem körül és milyen érzetek vannak jelen a testemben, anélkül, hogy ítélkeznénk.</p>
            </div>
            </b-col>
             <b-col cols="12" lg="6" class="side-gap">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                De hogyan csináljuk a gyakorlatban? Kristin Neff számos gyakorlata közül az önegyüttérzés napló egy mindenki számára elérhető eszköz. Amikor van pár perced, írj le a napodból olyan eseményeket, amik negatív érzésekkel töltöttek el, majd ezeket gondold át 3 szemszögből:</p>
                <p class="text">
                mindfulness: mit éreztél a helyzettel kapcsolatban? Milyen gondolataid, érzéseid, testi érzeteid keletkeztek? Próbáld meg ezeket ítélkezés nélkül leírni.<br><br>
                közös emberi: hogyan kapcsolódik a te tapasztalatod az általános emberi élményekhez?<br><br>
                ön-kedvesség: írj magadnak pár kedves, támogató, megértő mondatot, próbálj úgy együttérezni magaddal, ahogy egy barátoddal tennéd.</p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/egyutterzes.jpg" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="true"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Önegyüttérzés", 
        desc: "A bántalmazásból való kilépés, a trauma feldolgozása és a gyógyulás folyamán egyaránt nagyon fontos, hogy képesek legyünk kedves, elfogadó módon, törődéssel viszonyulni önmagunkhoz", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Önegyüttérzés",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "A bántalmazásból való kilépés, a trauma feldolgozása és a gyógyulás folyamán egyaránt nagyon fontos, hogy képesek legyünk kedves, elfogadó módon, törődéssel viszonyulni önmagunkhoz"        
    })
  }
}
</script>