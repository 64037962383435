<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Passzív bántalmazás és stonewalling
          </h2>
          <p class="hashText">– Elhanyagolás a párkapcsolatban</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" class="side-gap">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                A bántalmazás egyik kevésbé nyilvánvaló formája a passzív bántalmazás, aminek a párkapcsolatban a leggyakoribb megnyilvánulása az elhanyagolás. Ellentétben a nagyon egyértelmű, sokszor fekete vagy fehér fizikai vagy verbális abúzussal, a passzív bántalmazást sokkal nehezebb felismerni, így kezelni is. De akkor mégis mit nevezünk passzív bántalmazásnak? Azt, amikor az egyik fél rendszeresen figyelmen kívül hagyja a másikat, ignorálja az érzelmi vagy fizikai szükségleteit, ellehetetleníti a kommunikációt, érzelmileg, vagy akár fizikailag is kivonul a megoldásra váró konfliktusos helyzetekből. Együttműködés és kölcsönösség helyett közöny és érzelmi elérhetetlenség jellemzi a passzív bántalmazó személyt. </p>

                <p class="text">
                A passzív bántalmazás egy szélsőséges megnyilvánulásaként megjelenhet a stonewalling, amikor a személy nem hajlandó kommunikációt folytatni vagy problémákat megoldani a másik féllel, érzelmi távolságot igyekszik teremteni, aktívan kerüli a párbeszédet, esetleg a másik személy fizikai jelenlétét is teljesen figyelmen kívül hagyja. A stonewalling érzelmileg nagyon kimerítő az áldozat számára, mivel egyedül van hagyva a közös konfliktus érzelmi nehézségeivel és a feldolgozással.</p>
            </div>
            </b-col>
             <b-col cols="12" lg="6" class="side-gap">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Ha ezeket tapasztalod, akkor valószínűleg stonewallinggal állsz szemben:</p>
                <p class="text">
                -	A másik hirtelen abbahagyja a válaszadást.<br>
                -	Beszéd közben elfordul tőled.<br>
                -	Nem hajlandó válaszolni a kérdésekre.<br>
                -	Elkezd repetitív módon reagálni, újra és újra ugyanazt ismételgetve.<br>
                -	Elkezd valami mást csinálni, vagy úgy tesz, mintha nagyon elfoglalt lenne.<br>
                -	Félbeszakít beszéd közben, vagy más módon megakadályozza, hogy kifejezd a gondolataidat.<br>
                -	 Befejezettnek nyilvánítja a beszélgetést, függetlenül attól, hogy te szeretnél-e még mondani valamit, vagy folytatni a beszélgetést.<br>
                -	Úgy tesz, mintha nem hallana.<br>
                -	Figyelmen kívül hagy.<br>
                -	Elhárít minden felelősséget, és téged hibáztat, ahelyett, hogy megpróbálná megbeszélni a nehézségeket.<br>
                -	Fizikailag kilép a helyzetből, szó nélkül otthagy téged.<br>
                -	Nem válaszol az üzeneteidre, nem veszi fel a telefont.
                </p>
                <p class="text">
                Hogy a stonewalling mennyire ártalmas, az is mutatja, hogy John Gottman, amerikai pszichológus, a válás előrejelzését és a házassági stabilitást kutatva „az apokalipszis négy lovasa” metafórával írja le azokat a kommunikációs stílusokat, amelyek a leggyakrabban vezetnek a párkapcsolat végéhez. Eredményei szerint ezek a kritizálás, a megvetés, a védekezés és a stonewalling. </p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/passziv.jpg" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="true"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Passzív bántalmazás és stonewalling", 
        desc: "A bántalmazás egyik kevésbé nyilvánvaló formája a passzív bántalmazás, aminek a párkapcsolatban a leggyakoribb megnyilvánulása az elhanyagolás.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Passzív bántalmazás és stonewalling",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "A bántalmazás egyik kevésbé nyilvánvaló formája a passzív bántalmazás, aminek a párkapcsolatban a leggyakoribb megnyilvánulása az elhanyagolás."        
    })
  }
}
</script>