<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row id="main1" class="maintText__row">
            <b-col cols="12" class="pb-5 side-gap">
                <h2 style="mainText__mainName--maintxtx">
                    Testszégyenítés az online térben
                </h2>
                <p class="hashText">- A tökéletesség iránti igény</p>
            </b-col>
        </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                A tökéletesség iránti igény nagyon sok irányból bombáz minket: a közösségi média platformjain, a tévében, az újságokban mind-mind gyönyörű, tökéletes alakú modellek mosolyognak ránk, így nem meglepő, hogy nagyon sokan ezt tekintik mércének. A külsőségek mellett így nagyon könnyen elveszhetnek a belső értékek – nem az fog számítani, hogy ki mennyire önzetlen, segítő szándékú, hanem sokkal inkább az, hogy mennyire lapos a hasa vagy hibátlan a bőre. </p>

                <p class="text">
                Önmagunk és más emberek téves megítélése könnyen torkollhat testszégyenítésbe – főként az online felületeken, ahol nagyon könnyű az anonimitás álarca mögé rejtőzni. A közösségi média kommentszekciójában szinte naponta találkozhatunk ilyen és ehhez hasonló kérdésekkel, megjegyzésekkel:<br>-	Téged éheztetnek?<br>
                -	Ha valaki ennyire izmos, az már egyáltalán nem nőies…<br>
                -	Én ilyen narancsbőrös lábbal tuti, hogy nem vennék fel szoknyát!<br>
                -	Ezért kellene a chips falás helyett inkább pár guggolást csinálni…
                </p>

                <p class="text">
                Hajlamosak lehetünk azt hinni, hogy a bodyshaming csak a túlsúlyosak csoportját érinti, azonban az igazság az, hogy bármilyen alkattal rendelkező vagy életkorú személy könnyen az internet górcsöve alá kerülhet. A testszégyenítésnek fokozottan kitett korcsoport a kamaszok és fiatal felnőttek (főként lányok), ahol az önképpel kapcsolatos érzések nagy mértékben függenek a kortársak visszajelzéseitől. A negatív megjegyzések könnyen okozhatnak testkép- és evészavarokat – csakis a megfelelés miatt. </p>

                <p class="text">
                Mit tehetünk ellene? Az első és legfontosabb lépés, hogy tudatosítsuk magunkban, hogy bármit is látunk a kommentszekcióban, úgy vagyunk egyediek és tökéletesek, ahogy vagyunk! Szerencsére egyre inkább terjednek az úgynevezett body positivity, azaz a testpozitív megnyilvánulások, közösségi média fiókok. Fogadjuk el saját hibáinkat, és ez segíteni fog minket abban, hogy mások tökéletlenségeit is elfogadjuk. A folyamat lassú, de mindenképp megéri – hiszen az, hogy gátat szabjunk a testszégyenítésnek, közös érdekünk. </p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/test.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Testszégyenítés az online térben", 
        desc: "A tökéletesség iránti igény sok irányból bombáz minket: a közösségi média platformjain, a tévében, az újságokban mind-mind gyönyörű, hibátlan alakú modellek mosolyognak ránk, így nem meglepő, hogy nagyon sokan ezt tekintik mércének.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Testszégyenítés az online térben",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "A tökéletesség iránti igény sok irányból bombáz minket: a közösségi média platformjain, a tévében, az újságokban mind-mind gyönyörű, hibátlan alakú modellek mosolyognak ránk, így nem meglepő, hogy nagyon sokan ezt tekintik mércének."        
    })
  }
}
</script>